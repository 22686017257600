<template>
    <div id="certificate">
        <!-- <img class="hzsf" src="../../public//hzsf@2x.png" alt=""> -->
        <div class="topic">
            国家语委“十四五”科研项目/幼小语言能力测评
        </div>
        <div class="certificate_title">
            证书查询
        </div>
        <div class="iptBox">
            <input type="text" v-model="certificateAccount" placeholder="请输入证书编号">
            <div class="btn" @click=" inquire()">查询</div>
        </div>
        <div class="result" v-show="information">
            <p class="result_topic">全国幼小语言能力测评证书</p>
            <div class="result_box">
                <div class="result_type">
                    <span class="type_title">姓名：</span>
                    <span class="type_content">{{ information.name }}</span>
                </div>
                <div class="result_type">
                    <span class="type_title">性别：</span>
                    <span class="type_content">{{ information.sex }}</span>
                </div>
            </div>
            <div class="result_box2">
                <div class="result_type">
                    <span class="type_title">等级：</span>
                    <span class="type_content">{{ information.grade }}</span>
                </div>
                <div class="result_type">
                    <span class="type_title">年龄：</span>
                    <span class="type_content">{{ information.age }}</span>
                </div>
            </div>
            <div class="result_box">
                <div class="result_type">
                    <span class="type_title">成绩：</span>
                    <span class="type_content type_content2">{{ information.achievement }}</span>
                </div>
                <div class="result_type">
                    <span class="type_title">发证时间：</span>
                    <span class="type_content">{{ information.certificateIssuanceTime }}</span>
                </div>
            </div>
            <div class="result_box2">
                <div class="result_type">
                    <span class="type_title">证书编号：</span>
                    <span class="type_content">{{ information.certificateAccount }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Certificate',
    data() {
        return {
            certificateImg: "",//证书图片地址
            certificateAccount: "",//证书编号
            information: "",//证书信息
        }
    },
    mounted() { },
    methods: {
        // 查询证书
        inquire() {
            var that = this
            if (this.certificateAccount) {
                axios.get("https://edu.qsmiaoyu180.cn/edu/certificateTable/page?certificateAccount=" + this.certificateAccount,
                 ).then(res => {
                        if (res.data.data.records.length > 0) {
                            res.data.data.records.forEach(element => {
                                element.certificateIssuanceTime = element.certificateIssuanceTime.slice(0, 10)
                            });
                            that.certificateImg = res.data.data.records[0].certificateUrl
                            that.information = res.data.data.records[0]
                            console.log(res);
                        } else {
                            alert("证书编码有误，请检查确认无误后再次查询")
                        }
                    })
            } else {
                alert("请输入需要查询的证书编码")
                that.certificateImg = ""
                that.information = ""
            }
        },

    }
}
</script>
<style scoped>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#certificate {
    width: 100%;
    background: #FAFAFA;
    overflow-y: scroll;
    scrollbar-width: none;
}

.hzsf {
    width: 610px;
    height: 90px;
    margin: 20px 0;
}

.topic {
    font-weight: 500;
    font-size: 25px;
    color: #000000;
    letter-spacing: 8px;
    line-height: 80px;
}

.certificate_title {
    font-weight: 500;
    font-size: 40px;
    color: #000000;
    line-height: 80px;
    letter-spacing: 18px;
    text-align: center;
}

.iptBox {
    width: 1000px;
    height: 70px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
}

input {
    flex: 1;
    height: 50px;
    border: none;
    outline: none;
    padding: 15px;
    font-weight: 400;
    font-size: 25px;
    color: #000000;
}

input::placeholder {
    font-weight: 400;
    font-size: 25px;
    color: #888888;
}

.btn {
    width: 100px;
    height: 70px;
    background: #FB834E;
    border-radius: 0px 8px 8px 0px;
    font-weight: 400;
    font-size: 30px;
    color: #FFFFFF;
    line-height: 70px;
    text-align: center;
    cursor: pointer;
}

.result {
    width: 1000px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px 1px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 8px 8px;
    margin: 3px auto;
    text-align: left;
    padding-top: 30px;
}

.result_topic {
    font-weight: 500;
    font-size: 28px;
    color: #000000;
    margin: 0 0 15px 30px;
}

.result_box,
.result_box2 {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px 30px;
}

.result_type {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.type_title,
.type_content {
    font-weight: 400;
    font-size: 20px;
    color: #000000;
    line-height: 40px;
}

.type_title {
    width: 120px;
}

.type_content2 {

    color: #30BE26;
}

.result_box {
    background: rgba(255, 245, 241, 0.57);
}
</style>